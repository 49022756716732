import { apiPharmaInstance } from 'utils/axios';

// post
export const postSendEmailVendors = async (drugManufacturerId, payload) => {
    try {
        return await apiPharmaInstance.post(`/api/drug-manufacturers/${drugManufacturerId}/request-authorization`, {
            ...payload,
        });
    } catch (ex) {
        console.error(ex.message);
    }
};

export const disabledVendors = async (payload) => {
    return await apiPharmaInstance.post('/api/api-credential/restore', { ...payload });
};

export const getStatusByHubspotClient = async (posId) => {
    return await apiPharmaInstance.get(`/api/points-of-sale/${posId}/status/client`);
};