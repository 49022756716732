import { createContext, useContext, useMemo, useState } from 'react';

type ModalDiscounts = {
    state: boolean;
    discounts: any[];
    drugManufacturerName: string;
};

type ContextProps = {
    openModalDiscounts: ModalDiscounts;
    setOpenModalDiscounts: (v: ModalDiscounts) => void;
};

const defaultValues: ContextProps = {
    openModalDiscounts: {
        state: false,
        discounts: [],
        drugManufacturerName: '',
    },
    setOpenModalDiscounts: (v: ModalDiscounts) => {},
};

type Props = {
    children: React.ReactNode;
};

const ModalDiscountsContext = createContext<ContextProps>(defaultValues);

export const ModalDiscountsProvider = ({ children }: Props) => {
    const [openModalDiscounts, setOpenModalDiscounts] = useState<ModalDiscounts>(defaultValues.openModalDiscounts);

    const value: ContextProps = useMemo(
        () => ({
            openModalDiscounts,
            setOpenModalDiscounts,
        }),
        [openModalDiscounts],
    );

    return <ModalDiscountsContext.Provider value={value}>{children}</ModalDiscountsContext.Provider>;
};

export const useModalDiscountsContext = () => useContext(ModalDiscountsContext);
