import { createContext, useCallback, useContext, useMemo, useState } from 'react';

const ModalContextWithoutCredit = createContext(null);

export const ModalWithoutCredit = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderWithoutStock, setOrderWithoutStock] = useState(false);
    const [drugManufacturerName, setDrugManufacturerName] = useState('');
    const [typeModal, setTypeModal] = useState('');

    const openModal = useCallback((drugManufacturerName) => {
        setDrugManufacturerName(drugManufacturerName);
        setOrderWithoutStock(true);
        setIsModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    const contextValue = useMemo(
        () => ({
            isModalOpen,
            openModal,
            closeModal,
            orderWithoutStock,
            drugManufacturerName,
            typeModal,
            setTypeModal,
        }),
        [isModalOpen, orderWithoutStock, drugManufacturerName, typeModal],
    );

    return <ModalContextWithoutCredit.Provider value={contextValue}>{children}</ModalContextWithoutCredit.Provider>;
};

export const useModalWithoutCredit = () => {
    return useContext(ModalContextWithoutCredit);
};
