export const createAccountByFreeTrial = async (payload: any) => {
    try {
        const response = await fetch(`${process.env.NEXT_PUBLIC_API_PHARMA_HOST_URL}/api/account/free-trial`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });
        return response;
    } catch (err) {
        return err.message || err;
    }
};
