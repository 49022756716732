import React from 'react';
import useSWR from 'swr';

interface Filters {
    pageIndex?: number;
    search?: any;
    sort?: any;
    rows?: string | number;
    ordering?: string | number;
    country?: any;
}

interface ClientsProps extends Filters {}

export const useClients = ({ pageIndex, rows, search, sort, ordering, country }: ClientsProps) => {
    const { data: clients, mutate: mutateClients, isValidating } = useSWR(
        `/api/clients?&filters[search]=${search}&page=${pageIndex}&rows=${rows}&sorts=${sort ? sort : ''}:${ordering}`,
    );

    return { clients, mutateClients, isValidating };
};

export const useClientsOnlyAlerts = ({ pageIndex, rows, search, sort, ordering, country }: ClientsProps) => {
    // Construir la parte de la URL correspondiente al país
    const countryFilter = !!country ? country.map((item) => `&filters[countries][]=${item}`).join('') : '';

    // Construir la URL completa con el filtro del país
    const url = `/api/clients?&filters[search]=&page=${pageIndex}&rows=${rows}&sorts=${
        sort ? sort : ''
    }:${ordering}${countryFilter}`;

    // Utilizar useSWR con la URL construida
    const { data: clients, mutate: mutateClients, isValidating } = useSWR(url);

    return { clients, mutateClients, isValidating };
};

export const useClient = (id: string) => {
    const { data: client, mutate: mutateClient } = useSWR(`/api/clients/${id}`, {
        revalidateOnFocus: false,
        shouldRetryOnError: false,
    });
    return { client: client?.data, mutateClient };
};

export const useClientCountry = () => {
    const { data: contries } = useSWR(`/api/countries/`);
    return { countries: contries };
};
