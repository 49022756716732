import useSWR from 'swr';

export const useFetchRecommendedProducts = (point_of_sale_id: number | string | null, page: number = 1) => {
    const shouldFetch = point_of_sale_id !== null && point_of_sale_id !== undefined;

    const { data, mutate, isValidating } = useSWR(
        shouldFetch ? `api/recommended-products/${point_of_sale_id}?page=${page}` : null,
        {
            revalidateOnFocus: false, // No revalidar al recuperar el foco
            dedupingInterval: 300000, // 5 minutos para evitar solicitudes duplicadas
            shouldRetryOnError: false, // No reintentar en caso de error
        },
    );

    return { data, mutate, isValidating };
};
