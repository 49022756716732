import { createContext, useEffect, useState, useCallback, useContext } from 'react';
import { useRouter } from 'next/router';
// apis
import { getERP } from 'api';

export interface StatusProviderProps {
    setStatus(value: boolean): void;
    status: boolean;
    setControl(value: boolean): void;
    control: boolean;
    setRes(value: boolean): void;
    res: boolean;
    setResponse(value: boolean): void;
    response: boolean;
    count?: any;
    setCount(value: any): void;
    objSelect?: any;
    setObjSelect?: any;
    errorResponse?: any;
    setErrorResponse?: any;
    handleERPValue?: any;
    setCounterStep?(value: string): void;
    counterStep?: string;
    setIsTourOpen?(value: boolean): void;
    isTourOpen?: boolean;
    setDisplayModalOnBoarding?(value: boolean): void;
    displayModalOnBoarding?: boolean;
    hideSuggestionPDV?: boolean;
    setHideSuggestionPDV?(value: boolean): void;
    setContinueStatus(value: string): void;
    continueStatus: string;
}

const StatusContext = createContext<Omit<StatusProviderProps, 'children'>>(null);

export function StatusProvider({ children, ...props }) {
    const router = useRouter();
    const { clientId, client_id } = router.query || {};
    const [status, setStatus] = useState<boolean>(false);
    const [control, setControl] = useState<boolean>(false);
    const [response, setResponse] = useState<boolean>(false);
    const [errorResponse, setErrorResponse] = useState('');
    const [res, setRes] = useState<boolean>(true);
    const [count, setCount] = useState(1);
    const [objSelect, setObjSelect] = useState([]);
    const [counterStep, setCounterStep] = useState<string>('body');
    const [continueStatus, setContinueStatus] = useState<string>('');
    const [isTourOpen, setIsTourOpen] = useState<boolean>(true);
    const [displayModalOnBoarding, setDisplayModalOnBoarding] = useState<boolean>(true);
    const [hideSuggestionPDV, setHideSuggestionPDV] = useState<boolean>(false);
    const [erpLoaded, setErpLoaded] = useState<boolean>(false); // Para evitar múltiples llamadas

    // vistas habilitadas para llamar a erp-management-systems
    const viewsAllowed = ['/[clientId]/settings/POS/[posId]/posDetails', '/pos/create/[client_id]'];
    const client = clientId || client_id;

    // ✅ Usamos `useCallback` para evitar que `handleERPValue` cambie en cada render
    const handleERPValue = useCallback(async () => {
        if (client && !erpLoaded) {
            setErpLoaded(true); // Evita múltiples llamadas
            try {
                let res = await getERP(client);
                setObjSelect(res?.data?.data);
            } catch (error) {
                console.error('Error fetching ERP data:', error);
            }
        }
    }, [client, erpLoaded]);

    useEffect(() => {
        if (viewsAllowed.includes(router.pathname)) {
            handleERPValue();
        }
    }, [client]); // 🔹 Solo depende de `clientId`, no de `user` o `router.query`

    return (
        <StatusContext.Provider
            value={{
                status,
                setStatus,
                setControl,
                control,
                setRes,
                res,
                response,
                setResponse,
                count,
                setCount,
                objSelect,
                setObjSelect,
                errorResponse,
                setErrorResponse,
                handleERPValue,
                setCounterStep,
                counterStep,
                isTourOpen,
                setIsTourOpen,
                displayModalOnBoarding,
                setDisplayModalOnBoarding,
                hideSuggestionPDV,
                setHideSuggestionPDV,
                setContinueStatus,
                continueStatus,
            }}
        >
            {children}
        </StatusContext.Provider>
    );
}

export function useStatusContext() {
    const context = useContext(StatusContext);

    if (context === undefined) {
        throw new Error('useConfirmationContext must be used within a ConfirmationProvider');
    }

    return context;
}
