import { apiPharmaInstance } from 'utils/axios';

// uso en la home para droguerias sugeridas y conectadas
export const getDrugManufacturersHome = async ({ id, typeId = '', location = 0 }) => {
    let url = `/api/drug-manufacturers?sorts=name:asc&filters[point_of_sale_id]=${id}&includes=credentials&rows=1000`;
    if (typeId) {
        url += `&filters[type_id]=${typeId}`;
    }
    if (location) {
        url += `&filters[with_location]=${location}`;
    }

    try {
        return apiPharmaInstance
            .get(url)
            .then((res) => res.data.data)
            .catch((e) => console.error(e));
    } catch (ex) {
        console.error(ex.message);
    }
};

// uso en tabla para obtener las credenciales de una drogueria especifica.
export const getDrugManufacturerByIdWithCredentials = async (pointOfSaleId, drug_manufacturer_id) => {
    try {
        return apiPharmaInstance
            .get(
                `api/points-of-sale/${pointOfSaleId}/api-credentials?filters[drug_manufacturer_id]=${drug_manufacturer_id}`,
            )
            .then((res) => res.data.data)
            .catch((e) => console.error(e));
    } catch (ex) {
        console.error(ex.message);
    }
};

// nuevo endpoint get de errores para el banner de tabla comparativa
export const getDrugManufacturersWithFilter = async (orderId, filterType) => {
    try {
        return apiPharmaInstance
            .get(`api/orders/${orderId}/drug-manufacturers?filters[type]=${filterType}`)
            .then((res) => res.data.data)
            .catch((e) => console.error(e));
    } catch (ex) {
        console.error(ex.message);
    }
};
