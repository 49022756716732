// react
import { createContext, useContext, useEffect, useState } from 'react';
// external context
import { useCreateOrder } from './createOrder/create-order-context';
import { usePointOfSale } from '.';
// utils
import { isGestorERPFormat, isZettiFormat } from 'utils/pointOfSale';
import { useRouter } from 'next/router';
import { EX6229 } from 'config/flags';

// context
const DragAndDropContext = createContext(null);

export const DragAndDropProvider = ({ children, ...props }) => {
    // context
    const { setBlockerFormat, setErrors } = useCreateOrder();
    // state
    const [isDragging, setIsDragging] = useState(false);
    const [visibleDragDrop, setVisibleDragDrop] = useState(false);
    // add hooks by redesign home
    const { pointOfSale } = usePointOfSale();
    const { pathname, query } = useRouter();

    const isZetti = isZettiFormat(pointOfSale);
    const isGestorERP = isGestorERPFormat(pointOfSale);

    const handlerDragEnter = (e) => {
        e?.preventDefault();
        setIsDragging(true);
        setVisibleDragDrop(true);
    };
    const handleDragLeave = (e) => {
        e?.preventDefault();
        setIsDragging(false);
        if (EX6229) {
            setErrors(false);
            setBlockerFormat(false);
        }

        if (!isZetti && !isGestorERP) {
            setVisibleDragDrop(false);
        }
    };

    const openDragAndDropIconClose = () => {
        setVisibleDragDrop(!visibleDragDrop);
        setIsDragging(false);
        setBlockerFormat(false);
        setErrors(false);
    };

    const preventDefault = (e) => {
        e?.preventDefault();
    };

    const openZettiMode = () => {
        setVisibleDragDrop(true);
        setIsDragging(false);
        setBlockerFormat(false);
        setErrors(false);
    };

    useEffect(() => {
        if (pointOfSale && (isZetti || isGestorERP) && pathname === '/[clientId]/efficient-purchase/[posId]') {
            openZettiMode();
        } else if (pointOfSale && !isZetti && !isGestorERP && pathname === '/[clientId]/efficient-purchase/[posId]') {
            handleDragLeave(null);
        }
    }, [pointOfSale, query?.posId]);

    return (
        <DragAndDropContext.Provider
            value={{
                isDragging,
                visibleDragDrop,
                handlerDragEnter,
                handleDragLeave,
                preventDefault,
                openDragAndDropIconClose,
                setVisibleDragDrop,
                setIsDragging,
                ...props,
            }}
        >
            {children}
        </DragAndDropContext.Provider>
    );
};
export function useDragAndDrop() {
    const context = useContext(DragAndDropContext);

    if (context === undefined) {
        throw new Error('useSearchProductContext must be used within a ConfirmationProvider');
    }

    return context;
}
