import { createContext, useContext, useMemo, useState } from 'react';

type ContextProps = {
    minimunPurchase: any[];
    setMinimunPurchase: (obj: any) => void;
    showModalMinimunPurchase: boolean;
    setShowModalMinimunPurchase: (v: boolean) => void;
    removeMinimunPurchase: (v: number) => void;
    addMinimunPurchase: (v: number) => void;
    handlerModalMinimunPurchase: () => void;
};

const defaultValues: ContextProps = {
    minimunPurchase: [],
    setMinimunPurchase: (obj: any) => {},
    showModalMinimunPurchase: false,
    setShowModalMinimunPurchase: (v: boolean) => {},
    removeMinimunPurchase: (v: number) => {},
    addMinimunPurchase: (v: number) => {},
    handlerModalMinimunPurchase: () => {},
};

type Props = {
    children: React.ReactNode;
};

const MinimunPurchaseContext = createContext<ContextProps>(defaultValues);

export const MinimunPurchaseProvider = ({ children }: Props) => {
    const [minimunPurchase, setMinimunPurchase] = useState<any>(defaultValues.minimunPurchase);
    const [showModalMinimunPurchase, setShowModalMinimunPurchase] = useState<boolean>(
        defaultValues.showModalMinimunPurchase,
    );

    const removeMinimunPurchase = (value: number) => {
        if (minimunPurchase?.includes(value)) {
            setMinimunPurchase((prevProps) => prevProps?.filter((drugManufacturerId) => drugManufacturerId !== value));
        } else {
            return;
        }
    };

    const addMinimunPurchase = (value: number) => {
        if (!minimunPurchase?.includes(value)) {
            setMinimunPurchase((prevState) => [...prevState, value]);
        } else {
            return;
        }
    };

    const handlerModalMinimunPurchase = () => {
        setShowModalMinimunPurchase(!showModalMinimunPurchase);
    };

    const value: ContextProps = useMemo(
        () => ({
            minimunPurchase,
            setMinimunPurchase,
            showModalMinimunPurchase,
            setShowModalMinimunPurchase,
            removeMinimunPurchase,
            addMinimunPurchase,
            handlerModalMinimunPurchase,
        }),
        [
            minimunPurchase,
            setMinimunPurchase,
            showModalMinimunPurchase,
            setShowModalMinimunPurchase,
            removeMinimunPurchase,
            addMinimunPurchase,
            handlerModalMinimunPurchase,
        ],
    );

    return <MinimunPurchaseContext.Provider value={value}>{children}</MinimunPurchaseContext.Provider>;
};

export const useMinimunPurchaseContext = () => useContext(MinimunPurchaseContext);
