export const disabledButtonMakeOrder = () => {
    const button = getTagElement();
    if (button) {
        button.disabled = true;
    }
};

export const enabledButtonMakeOrder = () => {
    const button = getTagElement();
    if (button) {
        button.disabled = false;
    }
};

const getTagElement = () => {
    const button = document.getElementById('button-make-order') as HTMLButtonElement;
    return button;
};
