import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import useSWR from 'swr';
// configs
import { EX7579, UTM_CAMPAIGN_LG, UTM_CONTENT_LG, UTM_MEDIUM_LG, UTM_SOURCE_LG, UTM_TERM_LG } from 'config/flags';
// types
import { User, ResponseType, Role } from 'common/types';
import { apiPharmaInstance } from 'utils/axios';

interface Props {
    redirectTo: string;
    redirectIfFound: boolean;
}

const fetcher = async (url, token) => {
    const authToken = token || Cookies.get('token');
    if (!authToken) {
        throw new Error('Token no encontrado');
    }

    return apiPharmaInstance
        .get(url, {
            headers: { Authorization: `Bearer ${authToken}` },
        })
        .then((res) => res.data);
};

export function useUser({ redirectTo, redirectIfFound }: Partial<Props>) {
    const hasReplaced = useRef(false);
    const router = useRouter();
    const { pathname, query, replace } = router;
    const { token, expires_at, clientId } = query;
    const pathTable = pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison';

    useEffect(() => {
        if (EX7579) {
            if (token && expires_at && pathname === '/[clientId]/efficient-purchase') {
                // Calcular la diferencia entre la fecha actual y la fecha de expiración en días
                const currentDate = new Date();
                const diffInTime = Number(expires_at) - currentDate.getTime();
                const diffInDays = diffInTime / (1000 * 3600 * 24);
                Cookies.set('token', token, { expires: diffInDays });
                router.replace(`/${clientId}/efficient-purchase`, undefined, { shallow: true });
            }
        }
    }, [token]);

    const { data: user, mutate: mutateUser, error, isValidating } = useSWR<ResponseType<User>>(
        [`/api/users/me`, token],
        fetcher,
        {
            revalidateOnFocus: false, // No revalidar al recuperar el foco
            dedupingInterval: pathTable ? 900000 : 300000, // 5 o 15 minutos para evitar solicitudes duplicadas
            shouldRetryOnError: false, // No reintentar en caso de error
        },
    );

    const isAuthenticated = !!user?.data;
    const hasToken = EX7579 ? token || Cookies.get('token') : Cookies.get('token');
    const hasAuthorization = user?.data?.role_id === Role.Admin;
    const userLocaleCountryCode = user?.data?.client?.country_alfa_2;

    

    useEffect(() => {
        if (hasReplaced.current) return; // 🛑 Evita ejecutar más de una vez el reemplazo de URL
    
        if (user?.data?.free_trial_first_login) {
            const baseExpectedPath = `/${query.clientId}/efficient-purchase`;
            const expectedPath = query.posId ? `${baseExpectedPath}/${query.posId}` : baseExpectedPath;
    
            /* if (query.clientId && pathname !== expectedPath) {
                replace(
                    {
                        pathname: expectedPath,
                        query: {
                            utm_campaign: UTM_CAMPAIGN_LG,
                            utm_source: UTM_SOURCE_LG,
                            utm_medium: UTM_MEDIUM_LG,
                            utm_term: UTM_TERM_LG,
                            utm_content: UTM_CONTENT_LG,
                        },
                    },
                    undefined,
                    { shallow: true },
                );
    
                hasReplaced.current = true; // ✅ Marca que ya se hizo el reemplazo
                return;
            } */
        }
    
        if (user && user?.data) {
            localStorage.setItem('redirectToLogin', JSON.stringify(true));
        }
    
        if ((!redirectTo || !user) && hasToken && !error) {
            return;
        }
    
        if ((redirectTo && !redirectIfFound && !isAuthenticated) || (redirectIfFound && isAuthenticated)) {
            router.replace(redirectTo);
        }
    }, [user, redirectIfFound, redirectTo, isAuthenticated, hasToken, error]);

    return {
        user: user?.data,
        userLocaleCountryCode,
        hasAuthorization,
        isLoading: !error && !user?.data,
        mutateUser,
        isValidating,
    };
}
