import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { t } from 'i18next';
// config
import { EX3485, EX3771, EX7197 } from 'config/flags';
// apis
import { deleteItem, undoDeleteProduct } from 'api';
// components
import { useToast } from '@commons/EXComponentsLibrary';
// utils && hooks
import { ProductInterface } from 'common/types';
import {
    disabledButtonMakeOrder,
    enabledButtonMakeOrder,
} from '@commons/components/TableComparisonV2/utils/changeStateButtonMakeOrder';
import { useUser } from 'utils/hooks';

type ProductRemove = {
    index: number;
    barcode: string;
    clicked: boolean;
    orderProductId: number;
};

type ContextProps = {
    products: ProductInterface[];
    setProducts: (products: ProductInterface[]) => void;
    productGroups: ProductInterface[];
    setProductGroups: (products: ProductInterface[]) => void;
    setProductInfo: (product: ProductInterface) => void;
    cleanProducts: () => void;
    handleRemoveProduct: (information: ProductRemove) => void;
};

const defaultValues: ContextProps = {
    products: [],
    setProducts: (products: ProductInterface[]) => {},
    productGroups: [],
    setProductGroups: (products: ProductInterface[]) => {},
    setProductInfo: (product: ProductInterface) => {},
    cleanProducts: () => {},
    handleRemoveProduct: (information: ProductRemove) => {},
};

type Props = {
    children: React.ReactNode;
};

const ProductsContext = createContext<ContextProps>(defaultValues);

export const ProductsProvider = ({ children }: Props) => {
    const { user } = useUser({});
    const EX5266 = user?.EX5266; //ff hot fix calculate footer
    const EX5267 = user?.EX5267; //ff hot fix calculate footer when select all column

    const {
        replace,
        query: { orderId },
    } = useRouter();

    const { showToaster, clearToaster } = useToast();

    const [products, setProducts] = useState<ProductInterface[]>(defaultValues.products);
    const [productGroups, setProductGroups] = useState<ProductInterface[]>(defaultValues.productGroups);
    const productsRef: any = useRef(products);
    const productGroupsRef: any = useRef(products);

    useEffect(() => {
        productsRef.current = products;
    }, [products]);

    useEffect(() => {
        productGroupsRef.current = productGroups;
    }, [productGroups]);

    const updateProduct = useCallback(
        (item: ProductInterface) => {
            const currentProducts = productsRef.current;
            const currentProductGroups = productGroupsRef.current;

            const idx = currentProducts?.findIndex(({ barcode }) => item?.barcode === barcode);
            const idxByProductsGroups =
                EX3485 && currentProductGroups?.findIndex(({ barcode }) => item?.barcode === barcode);
            const findGenerics = currentProductGroups?.filter((object) => object?.generics?.length > 0);
            const isGeneric = findGenerics
                ?.map((producto) => {
                    return {
                        ...producto,
                        generics: producto?.generics?.filter((generic) => generic.barcode === item?.barcode),
                    };
                })
                ?.filter((producto) => producto.generics.length > 0);
            const idxByProductsGroupsGeneric = currentProductGroups?.findIndex(
                ({ barcode }) => isGeneric[0]?.barcode === barcode,
            );

            // validation with FF ON and length for prevention
            if (EX3485 && currentProductGroups.length) {
                // It is necessary to update the product Groups when the
                // quantity is updated because otherwise it will be left with old data
                const newProductGroups: any = currentProductGroups;
                if (isGeneric?.length) {
                    const newQuantity = EX7197
                        ? item?.quantity === 0 && isGeneric?.generics?.some((generic) => generic.quantity > 0)
                            ? item.quantity
                            : newProductGroups[idxByProductsGroupsGeneric].quantity
                        : item?.quantity;

                    newProductGroups[idxByProductsGroupsGeneric].quantity = newQuantity;

                    // newProductGroups[idxByProductsGroupsGeneric].products = item?.products;

                    setProductGroups(newProductGroups);
                } else {
                    newProductGroups[idxByProductsGroups].quantity = item?.quantity;

                    if (EX5266) {
                        newProductGroups[idxByProductsGroups].products = item?.products;
                    }
                }

                setProductGroups(newProductGroups);
            }

            const newProductArray: ProductInterface[] = EX5267 ? currentProducts : [...currentProducts];
            newProductArray[idx] = item;
            if (EX3771) {
                setProducts((oldState) => {
                    const combinedProducts: any = [...oldState, ...newProductArray];

                    // Usar un objeto para realizar un seguimiento de los productos únicos por order_product_id
                    const uniqueProductsMap: any = combinedProducts.reduce((map, product) => {
                        map[product.order_product_id] = product;
                        return map;
                    }, {});

                    // Convertir el objeto de productos únicos de nuevo a un array
                    const uniqueProductsArray: any = Object.values(uniqueProductsMap);

                    // Actualizar el estado con el array de productos únicos
                    return uniqueProductsArray;
                });
            } else {
                setProducts([...newProductArray]);
            }
        },
        [products, productGroups],
    );

    // función encargada de actualizar información de producto cuando sufre alguna actualización
    const setProductInfo = useCallback((item: ProductInterface) => {
        const currentProducts = productsRef.current;
        const productExists = currentProducts?.some(({ barcode }) => barcode === item?.barcode);
        if (EX3771) {
            if (productExists) {
                updateProduct(item);
            } else {
                setProducts((prev) => [...prev, item]);
            }
        } else {
            if (productExists) {
                updateProduct(item);
            } else {
                setProducts((prev) => [...prev, item]);
            }
        }
    }, []);

    const cleanProducts = () => {
        setProducts((prevState) => []);
    };

    // función encargada de eliminar producto
    const handleRemoveProduct = useCallback((information: ProductRemove): void => {
        const currentProducts = productsRef.current;
        const currentProductGroups = productGroupsRef.current;

        const handleUndo = async (information: any) => {
            enabledButtonMakeOrder();
            try {
                undoItem(information.barcode, information.index);
                await undoDeleteProduct(+orderId, information.orderProductId);
                clearToaster();
            } catch {
                clearToaster();
            }
        };

        const undoItem = async (barcode, index = 0) => {
            // get product
            let filteredProduct = currentProducts.find((e) => barcode == e.barcode);
            // search product in products
            let filteredProducts = currentProducts.filter((e) => filteredProduct.barcode !== e.barcode);
            // search product in product-groups
            let filteredProductGroups = currentProductGroups?.filter((e) => filteredProduct.barcode !== e.barcode);
            // push in correct position
            filteredProducts.splice(index, 0, filteredProduct);
            filteredProductGroups.splice(index, 0, filteredProduct);
            // set states
            setProductGroups(filteredProductGroups);
            setProducts(filteredProducts);
        };

        const removeItemInState = async (information) => {
            const { barcode } = information;
            let filteredProduct = currentProducts.find((e) => barcode == e.barcode);

            let filteredProducts = currentProducts.filter((e) => filteredProduct.barcode !== e.barcode);
            let filteredProductGroups = currentProductGroups?.filter((e) => filteredProduct.barcode !== e.barcode);

            setProducts(filteredProducts);
            setProductGroups(filteredProductGroups);
            const productId = filteredProduct.order_product_id;
            await deleteItem(orderId, productId);
            enabledButtonMakeOrder();
        };

        disabledButtonMakeOrder();
        if (currentProducts.length == 1) {
            replace('/efficient-purchase/');
            return;
        }

        removeItemInState(information);
        showToaster({
            message: {
                description: t('shoppingCart.shoppingCart_Thirteen'),
            },
            type: 'success',
            duration: 5000,
            actionButton: {
                label: t('efficientPurchasing.comparative.efficientPurchasing_comparativeFourteen'),
                action: () => handleUndo(information),
            },
        });
    }, []);

    const value: ContextProps = useMemo(
        () => ({
            products,
            setProducts,
            productGroups,
            setProductGroups,
            setProductInfo,
            cleanProducts,
            handleRemoveProduct,
        }),
        [products, productGroups],
    );

    return <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>;
};

export const useProductsContext = () => useContext(ProductsContext);
