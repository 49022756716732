import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDrugManufacturersData } from './hooks';
import { useCredentialErrors } from 'context/CredentialsErrors/CredentialsErrors';

type ContextProps = {
    drugManufacturer: any;
    setDrugManufacturer: (obj: any) => void;
    enabledOnOff: boolean;
    setEnabledOnOff: (v: boolean) => void;
    isEnabledDrugManufacturer: (id: any) => boolean;
    isFailedConnectionByDrugManufacturerId: (id: any) => boolean;
    lastDrugManufacturerEnabled: () => any;
    mutateDrugManufacturers: () => void;
};

const defaultValues: ContextProps = {
    drugManufacturer: [],
    setDrugManufacturer: (obj: any) => {},
    enabledOnOff: false,
    setEnabledOnOff: (v: boolean) => {},
    isEnabledDrugManufacturer: (id: number) => false,
    isFailedConnectionByDrugManufacturerId: (id: any) => false,
    lastDrugManufacturerEnabled: () => {},
    mutateDrugManufacturers: () => {},
};

type Props = {
    children: React.ReactNode;
};

const DrugManufacturersContext = createContext<ContextProps>(defaultValues);

export const DrugManufacturersProvider = ({ children }: Props) => {
    const {
        drugManufacturersData,
        oneDisabledDrugManufacturerData,
        mutateDrugManufacturers,
    } = useDrugManufacturersData();
    const [drugManufacturer, setDrugManufacturer] = useState(defaultValues.drugManufacturer);
    const [enabledOnOff, setEnabledOnOff] = useState<boolean>(defaultValues.enabledOnOff);
    const { isFetchingUpdate, extractedDataError } = useCredentialErrors();

    const isEnabledDrugManufacturer = useCallback(
        (drugManufacturerId): boolean => {
            const d = drugManufacturer?.find(({ id }) => id === drugManufacturerId);
            return d?.enabled;
        },
        [drugManufacturer],
    );

    const isFailedConnectionByDrugManufacturerId = useCallback(
        (drugManufacturerId): boolean => {
            return extractedDataError?.some(({ id }) => id === drugManufacturerId);
        },
        [extractedDataError],
    );

    const lastDrugManufacturerEnabled = useCallback(
        () =>
            drugManufacturer?.filter(
                (item) => item.enabled === true && !isFailedConnectionByDrugManufacturerId(item.id),
            ),
        [drugManufacturer],
    );

    useEffect(() => {
        setDrugManufacturer(drugManufacturersData);
        setEnabledOnOff(oneDisabledDrugManufacturerData);
    }, [drugManufacturersData]);

    useEffect(() => {
        if (!!isFetchingUpdate) {
            mutateDrugManufacturers();
        }
    }, [isFetchingUpdate]);

    const value: ContextProps = useMemo(
        () => ({
            drugManufacturer,
            setDrugManufacturer,
            enabledOnOff,
            setEnabledOnOff,
            mutateDrugManufacturers,
            isEnabledDrugManufacturer,
            isFailedConnectionByDrugManufacturerId,
            lastDrugManufacturerEnabled,
        }),
        [drugManufacturer, enabledOnOff],
    );

    return <DrugManufacturersContext.Provider value={value}>{children}</DrugManufacturersContext.Provider>;
};

export const useDrugManufacturersContext = () => useContext(DrugManufacturersContext);
