import { createContext, useContext } from 'react';
import { useRouter } from 'next/router';
// configs
import { EX3771 } from 'config/flags';
// contexts
import { usePointOfSale } from 'context';
// utils && hooks
import { useFetchRecommendedProducts } from 'utils/hooks/useRecommendedProducts/useFetchRecommendedProducts';
import { isGestorERPFormat, isZettiFormat } from 'utils/pointOfSale';
// interfaces
import { RecommendedProductsProviderProps } from './recommended-products-interface';

const RecommendedProductsContext = createContext<Omit<RecommendedProductsProviderProps, 'children'>>(null);

export function RecommendedProductsProvider({ children, ...props }) {
    // use router
    const {
        pathname,
        query: { posId },
    } = useRouter();

    const { pointOfSale } = usePointOfSale();

    // zetti
    const isZetti = isZettiFormat(pointOfSale);
    const isGestorERP = isGestorERPFormat(pointOfSale);

    // Validar `posId` como número o null
    const validPosId =
        EX3771 && pathname.split('/').at(-1) === '[posId]' && posId && !isZetti && !isGestorERP ? Number(posId) : null;

    // Hook SWR para obtener los productos recomendados
    const { data, isValidating } = useFetchRecommendedProducts(validPosId, 1);

    return (
        <RecommendedProductsContext.Provider
            value={{
                recommendedProducts: data?.data,
                fetching: isValidating,
                ...props,
            }}
        >
            {children}
        </RecommendedProductsContext.Provider>
    );
}

export function useRecommendedProducts() {
    const context = useContext(RecommendedProductsContext);

    if (context === undefined) {
        throw new Error('useRecommendedProducts must be used within a ConfirmationProvider');
    }

    return context;
}
