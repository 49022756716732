import { Identity, DrugManufacturer, Order, Product } from 'common/types';

export enum TreackStatusNameType {
    PROCESSED = 1,
    IN_PROGRESS = 2,
    PROCESSING_ORDER = 7,
}
interface TrackStatus extends Identity<Number> {
    name: TreackStatusNameType;
}

export type TracNumber = number | 'Envío manual' | 'No provisto' | 'Llama a Nadro para reconfirmar';

export type PaymentMethod = {
    id: number;
    name: string;
    selected: boolean;
};

export type PaymentMethodPerDelivery = {
    deliveryId: number;
    paymentMethods: PaymentMethod[];
};

export type DeliveryTime = {
    delivery_id: number;
    delivery_time_id: number;
};

export type Credit = {
    detail: {
        amount_available: number;
    };
};

export interface OrderDelivery extends Identity<string> {
    order_id: number;
    drug_manufacturer_id: number;
    number: string;
    total: number;
    total_percentage: number;
    drug_manufacturer: DrugManufacturer;
    track_number?: TracNumber;
    track_status: TrackStatus;
    order: Order;
    unit_products_quantity?: number;
    free_unit_products_quantity?: number;
    products: Product[];
    status: {
        message: 'ok' | 'error';
        ws_status_code: 200 | null;
        id?: number;
        name?: string;
        user_message?: 'string';
    };
    credit: Credit;
    delivery_times?: any[];
    payment_methods: PaymentMethod[] | null;
}

interface Deliveries {
    order_id: number;
    drug_manufacturer_id: number;
    number: string;
    total: number;
    total_percentage: number;
    drug_manufacturer: DrugManufacturer;
    track_number?: TracNumber;
    track_status: TrackStatus;
    order: Order;
    unit_products_quantity?: number;
    free_unit_products_quantity?: number;
    products: Product[];
    status: {
        message: 'ok' | 'error';
        ws_status_code: 200 | null;
        id?: number;
        name?: string;
        user_message?: 'string';
    };
    credit: Credit;
    delivery_times?: any[];
    payment_methods: PaymentMethod[] | null;
}

export interface NewOrderDelivery extends Identity<string> {
    deliveries: Deliveries;
    products_quantity: number;
    unit_products_quantity: number;
    free_unit_products_quantity: number;
}
