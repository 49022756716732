import { createContext, useContext, useEffect, useMemo, useState } from 'react';

type ContextProps = {
    footerValues: any;
    setFooterValues: (v: any) => void;
    youAreSavings: string;
    setYouAreSavings: (v: string) => void;
};

const defaultValues: ContextProps = {
    footerValues: {},
    setFooterValues: (obj: any) => {},
    youAreSavings: '0',
    setYouAreSavings: (v: string) => {},
};

type Props = {
    children: React.ReactNode;
    data: any[];
};

const FooterContext = createContext<ContextProps>(defaultValues);

export const FooterProvider = ({ children, data }: Props) => {
    const [footerValues, setFooterValues] = useState<any>(defaultValues.footerValues);
    const [youAreSavings, setYouAreSavings] = useState<any>(defaultValues.youAreSavings);

    useEffect(() => {
        if(Object.keys(data).length > 0) {
            setFooterValues(data);
        }
    }, [data]);

    const value = useMemo(
        () => ({
            footerValues,
            setFooterValues,
            youAreSavings,
            setYouAreSavings,
        }),
        [footerValues, youAreSavings],
    );

    return <FooterContext.Provider value={value}>{children}</FooterContext.Provider>;
};

export const useFooterContext = () => useContext(FooterContext);
