import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { getStatusByHubspotClient } from 'api/vendors/vendors';
import { useRouter } from 'next/router';

const ALLOWED_HUBSPOT_CLIENTS = [1, 2];

interface HubspotStatusContextType {
    statusHubspot: any;
    disabledHubspotClient: boolean;
}

const HubspotStatusContext = createContext<HubspotStatusContextType | undefined>(undefined);
type Props = {
    children: any;
};
export const HubspotStatusProvider = ({ children }: Props) => {
    const router = useRouter();
    const { query } = router;
    const [statusHubspot, setStatusHubspot] = useState<any>(null);

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const response = await getStatusByHubspotClient(query.posId);
                setStatusHubspot(response?.data?.data || null);
            } catch (error) {
                console.error('Error fetching Hubspot status:', error);
            }
        };

        if (query.posId) fetchStatus();
    }, [query.posId]);

    const disabledHubspotClient = !(
        statusHubspot?.hubspot_status_id?.id && ALLOWED_HUBSPOT_CLIENTS.includes(statusHubspot.hubspot_status_id.id)
    );

    return (
        <HubspotStatusContext.Provider value={{ statusHubspot, disabledHubspotClient }}>
            {children}
        </HubspotStatusContext.Provider>
    );
};

export const useHubspotStatus = () => {
    const context = useContext(HubspotStatusContext);
    if (!context) {
        throw new Error('useHubspotStatus must be used within a HubspotStatusProvider');
    }
    return context;
};
