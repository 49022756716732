import { createContext, useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
// configs
import { EX7610 } from 'config/flags';
// apis
import { getDrugManufacturersWithFilter } from 'api';
// utils && hooks
import { EErrorCode } from 'utils/enums/comparativeAlerts/comparativeAlerts';
import { useDrugManufacturersByOrderId } from 'utils/hooks';
import { DRUGMANUFACTURER_FILTER_TYPE } from 'utils/enums';

const CredentialErrorsContext = createContext(null);

export const CredentialErrorProvider = ({ children }) => {
    // next
    const {
        query: { orderId },
    } = useRouter();
    // state
    const [drugmanufacturersEnabled, setDrugmanufacturersEnabled] = useState(null);
    const [isFetchingUpdate, setIsFetchingUpdate] = useState(false);
    // credenciales que se lograron actualizar
    const [updatedCredentials, setUpdatedCredentials] = useState<any | number>([]);
    // Se cierra el drawer y se actualizo
    const [closeDrawerAndReload, setCloseDrawerAndReload] = useState(false);
    // new swr by drugManufacturers
    const { drugManufacturersResponse, mutateDrugManufacturers } = useDrugManufacturersByOrderId(
        EX7610 ? undefined : orderId,
    );

    const validErrorCodes = new Set([
        EErrorCode.UNKNOWN_ERROR,
        EErrorCode.WRONG_USERNAME_PASSWORD,
        EErrorCode.OPERATION_FORBIDDEN,
        EErrorCode.CREDIT_LIMIT_REACHED,
        EErrorCode.REASON_PROVIDED,
        EErrorCode.EXPIRED_PASSWORD,
        EErrorCode.MISSING_ACCOUNT,
        EErrorCode.MISSING_DISPATCH_ADDRESS,
        EErrorCode.MISSING_FORMAT,
    ]);

    useEffect(() => {
        if (EX7610) {
            const fetchDrugmanufacturersWithErrors = async () => {
                try {
                    const response: any = await getDrugManufacturersWithFilter(
                        orderId,
                        DRUGMANUFACTURER_FILTER_TYPE.WITH_ERRORS,
                    );
                    setDrugmanufacturersEnabled(response);
                } catch (error) {
                    console.error('Error fetching drug manufacturers:', error);
                }
            };
            if (orderId) {
                fetchDrugmanufacturersWithErrors();
            }
        }
    }, [orderId]);

    // new effect
    useEffect(() => {
        if (!EX7610) {
            if (drugManufacturersResponse?.data) {
                setDrugmanufacturersEnabled(drugManufacturersResponse?.data);
            }
        }
    }, [drugManufacturersResponse]);

    // new effect
    useEffect(() => {
        if (!EX7610) {
            if (!!isFetchingUpdate) {
                mutateDrugManufacturers();
            }
        }
    }, [isFetchingUpdate]);

    const extractedDataError = drugmanufacturersEnabled
        ?.filter(({ error_type }) => error_type && error_type.length !== 0 && validErrorCodes.has(error_type.id))
        .map((item) => ({ ...item }));

    const validation = (id: any) => EX7610 ? ![2,4,10].includes(id) : id === 1;

    const extractedUnknownError = drugmanufacturersEnabled
    ?.filter(({ error_type }) => error_type?.length !== 0 && validation(error_type?.id))
    .map((item) => {
        return {
            ...item,
        };
    });
    
    const extractedCredentialErrors = extractedDataError?.filter((elem) => elem?.error_type?.id === 2);

    const extractedCreditError = extractedDataError?.filter((elem) => elem?.error_type?.id === 4);

    const extractedMissingFormat = extractedDataError?.filter(
        (elem) => elem?.error_type?.id === EErrorCode.MISSING_FORMAT,
    );

    const quantityCredentialsWithErrors = extractedCredentialErrors?.length;

    const quantityCreditWithErrors = extractedCreditError?.length;

    return (
        <CredentialErrorsContext.Provider
            value={{
                drugmanufacturersEnabled,
                extractedCredentialErrors,
                extractedUnknownError,
                quantityCredentialsWithErrors,
                extractedDataError,
                setIsFetchingUpdate,
                isFetchingUpdate,
                updatedCredentials,
                setUpdatedCredentials,
                closeDrawerAndReload,
                setCloseDrawerAndReload,
                quantityCreditWithErrors,
                mutateDrugManufacturers: EX7610 ? () => {} : mutateDrugManufacturers,
                extractedMissingFormat,
            }}
        >
            {children}
        </CredentialErrorsContext.Provider>
    );
};

export const useCredentialErrors = () => {
    return useContext(CredentialErrorsContext);
};
